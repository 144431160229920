import { useState, useEffect } from "react";
import { Frame, Loading, Header, Input } from "../components";
import { db, auth, storage } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate, Link } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
import { ObjetoVazio } from "../components/Formatar";

const Financeiro = () => {
  const [dados, setDados] = useState({});
  const [alterar, setAlterar] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();
  const hoje = moment().format();

  const setData = async () => {
    await setDoc(doc(db, "furg2024", uid), alterar, { merge: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!ObjetoVazio(alterar)) {
      setLoading(true);
      setData().then(() => {
        alert("Enviado com sucesso.");
        window.location.reload(false);
      });
    }
  };

  const uploadFile = async (file, docPath, docUrl) => {
    if (!file) return;

    setLoading(true);

    const path = ref(storage, `furg2024/${uid}/${docPath}`);

    const upload = uploadBytesResumable(path, file, file.type);

    upload.on(
      "state_changed",
      () => {},
      () => {},
      () => {
        getDownloadURL(upload.snapshot.ref).then((url) => {
          setAlterar({ ...alterar, [docUrl]: url, [docPath]: "Enviado" });
          setDados({ ...dados, [docUrl]: url });
          setLoading(false);
        });
      }
    );
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "furg2024", uid));

    const data = qry.data();

    if (data?.status === "a") {
      if (data?.aceite25) {
        setDados(data);
        setLoading(false);
      } else {
        navigate("../renovacao");
      }
    } else {
      navigate("../home");
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      {
        <Frame
          title="Financeiro"
          body={
            <div>
              <table className="table table-sm table-striped table-bordered table-hover my-4">
                <thead>
                  <tr>
                    <th>Mês</th>
                    <th>Valor</th>
                    <th>Recibo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Setembro 2024</td>
                    <td>
                      {dados?.pagto0924 ||
                        (hoje > "2024-09-07" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto0924 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "0924" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Outubro 2024</td>
                    <td>
                      {dados?.pagto1024 ||
                        (hoje > "2024-10-08" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto1024 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "1024" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Novembro 2024</td>
                    <td>
                      {dados?.pagto1124 ||
                        (hoje > "2024-11-08" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto1124 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "1124" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>

                  <tr>
                    <td>Dezembro 2024</td>
                    <td>
                      {dados?.pagto1224 ||
                        (hoje > "2024-12-07" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto1224 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "1224" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Janeiro 2025</td>
                    <td>
                      {dados?.pagto0125 ||
                        (hoje > "2025-01-09" ? "R$ 610,00" : "R$ 550,00")}
                    </td>
                    <td>
                      {dados?.pagto0125 === "Pago" && (
                        <Link to="/recibo" state={{ mes: "0125" }}>
                          Abrir
                        </Link>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              <div className="justify mb-4">
                <p>
                  <i>
                    Para pagar sua mensalidade, faça um pix para a chave abaixo
                    e encaminhe o comprovante no mês correspondente.
                  </i>
                </p>
              </div>

              <div className="mb-4">
                <h5>Chave Pix CNPJ:</h5>
                <h5> 92.189.612/0001-92</h5>
              </div>

              <form className="mb-4 text-start" onSubmit={handleSubmit}>
                {!dados?.pagto0924 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0924"
                        label="Setembro 2024"
                        type="file"
                        url={dados?.urlPagto0924}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0924",
                            "urlPagto0924"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!dados?.pagto1024 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto1024"
                        label="Outubro 2024"
                        type="file"
                        url={dados?.urlPagto1024}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto1024",
                            "urlPagto1024"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!dados?.pagto1124 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto1124"
                        label="Novembro 2024"
                        type="file"
                        url={dados?.urlPagto1124}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto1124",
                            "urlPagto1124"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!dados?.pagto1224 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto1224"
                        label="Dezembro 2024"
                        type="file"
                        url={dados?.urlPagto1224}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto1224",
                            "urlPagto1224"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!dados?.pagto0125 && (
                  <Row className="mt-2">
                    <Col>
                      <Input
                        id="urlPagto0125"
                        label="Janeiro 2025"
                        type="file"
                        url={dados?.urlPagto0125}
                        accept=".jpg, .jpeg, .png, .pdf"
                        onChange={(e) =>
                          uploadFile(
                            e.target.files[0],
                            "pagto0125",
                            "urlPagto0125"
                          )
                        }
                      />
                    </Col>
                  </Row>
                )}

                {!ObjetoVazio(alterar) && (
                  <Row className="mt-2">
                    <Col className="d-grid">
                      <button type="submit" className="btn btn-primary">
                        ENVIAR
                      </button>
                    </Col>
                  </Row>
                )}
              </form>
            </div>
          }
        />
      }
    </>
  );
};

export default Financeiro;
