export const Nome = (nome = "") => {
  let split = nome.trim().split(" ");

  if (split.length > 1) return split[0] + " " + split.pop();
  else return split;
};

export const ObjetoVazio = (object = {}) => {
  if (Object.keys(object).length > 0) return false;
  else return true;
};
