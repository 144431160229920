import { useState, useEffect } from "react";
import { Frame, Loading, Header, Switch } from "../components";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const Form4 = () => {
  const [dados, setDados] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const handleSwitch = (name) => {
    setDados({ ...dados, [name]: !dados[name] });
  };

  const setData = async () => {
    await setDoc(doc(db, "furg2024", uid), dados, {
      merge: true,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setData().then(() => navigate("../form5"));
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "furg2024", uid));

    const data = qry.data();

    if (data?.status) {
      navigate("../home");
    } else {
      setDados(data);
      setLoading(false);
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      <Frame
        body={
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            className="text-start"
            noValidate
          >
            <div className="row mt-3">
              <div className="col">
                <b className="ps-3">Manhã</b>
                <Switch
                  id="idaSegMan"
                  label="Segunda"
                  checked={dados?.idaSegMan}
                  onChange={() => handleSwitch("idaSegMan")}
                />
                <Switch
                  id="idaTerMan"
                  label="Terça"
                  checked={dados?.idaTerMan}
                  onChange={() => handleSwitch("idaTerMan")}
                />
                <Switch
                  id="idaQuaMan"
                  label="Quarta"
                  checked={dados?.idaQuaMan}
                  onChange={() => handleSwitch("idaQuaMan")}
                />
                <Switch
                  id="idaQuiMan"
                  label="Quinta"
                  checked={dados?.idaQuiMan}
                  onChange={() => handleSwitch("idaQuiMan")}
                />
                <Switch
                  id="idaSexMan"
                  label="Sexta"
                  checked={dados?.idaSexMan}
                  onChange={() => handleSwitch("idaSexMan")}
                />
              </div>
              <div className="col">
                <b className="ps-3">Tarde</b>
                <Switch
                  id="idaSegTar"
                  label="Segunda"
                  checked={dados?.idaSegTar}
                  onChange={() => handleSwitch("idaSegTar")}
                />
                <Switch
                  id="idaTerTar"
                  label="Terça"
                  checked={dados?.idaTerTar}
                  onChange={() => handleSwitch("idaTerTar")}
                />
                <Switch
                  id="idaQuaTar"
                  label="Quarta"
                  checked={dados?.idaQuaTar}
                  onChange={() => handleSwitch("idaQuaTar")}
                />
                <Switch
                  id="idaQuiTar"
                  label="Quinta"
                  checked={dados?.idaQuiTar}
                  onChange={() => handleSwitch("idaQuiTar")}
                />
                <Switch
                  id="idaSexTar"
                  label="Sexta"
                  checked={dados?.idaSexTar}
                  onChange={() => handleSwitch("idaSexTar")}
                />
              </div>
              <div className="col">
                <b className="ps-3">Noite</b>
                <Switch
                  id="idaSegNoi"
                  label="Segunda"
                  checked={dados?.idaSegNoi}
                  onChange={() => handleSwitch("idaSegNoi")}
                />
                <Switch
                  id="idaTerNoi"
                  label="Terça"
                  checked={dados?.idaTerNoi}
                  onChange={() => handleSwitch("idaTerNoi")}
                />
                <Switch
                  id="idaQuaNoi"
                  label="Quarta"
                  checked={dados?.idaQuaNoi}
                  onChange={() => handleSwitch("idaQuaNoi")}
                />
                <Switch
                  id="idaQuiNoi"
                  label="Quinta"
                  checked={dados?.idaQuiNoi}
                  onChange={() => handleSwitch("idaQuiNoi")}
                />
                <Switch
                  id="idaSexNoi"
                  label="Sexta"
                  checked={dados?.idaSexNoi}
                  onChange={() => handleSwitch("idaSexNoi")}
                />
              </div>
              <div className="col">
                <b className="ps-3">Na Linha</b>
                <Switch
                  id="idaSegLin"
                  label="Segunda"
                  checked={dados?.idaSegLin}
                  onChange={() => handleSwitch("idaSegLin")}
                />
                <Switch
                  id="idaTerLin"
                  label="Terça"
                  checked={dados?.idaTerLin}
                  onChange={() => handleSwitch("idaTerLin")}
                />
                <Switch
                  id="idaQuaLin"
                  label="Quarta"
                  checked={dados?.idaQuaLin}
                  onChange={() => handleSwitch("idaQuaLin")}
                />
                <Switch
                  id="idaQuiLin"
                  label="Quinta"
                  checked={dados?.idaQuiLin}
                  onChange={() => handleSwitch("idaQuiLin")}
                />
                <Switch
                  id="idaSexLin"
                  label="Sexta"
                  checked={dados?.idaSexLin}
                  onChange={() => handleSwitch("idaSexLin")}
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-sm-12 d-grid">
                <button type="submit" className="btn btn-primary">
                  ENVIAR
                </button>
              </div>
            </div>
          </form>
        }
        title="Grade de Embarque"
      />
    </>
  );
};

export default Form4;
