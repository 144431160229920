import { useState, useEffect } from "react";
import { Frame, Loading, Header } from "../components";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { Button, Modal } from "react-bootstrap";
import moment from "moment";

const Renovacao = () => {
  const [errorModal, setErrorModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [dados, setDados] = useState({});
  const [loading, setLoading] = useState(true);

  const saldoMes = "saldo" + moment().format("MMYY");

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const openErrorModal = () => setErrorModal(true);
  const openConfirmModal = () => setConfirmModal(true);
  const closeErrorModal = () => setErrorModal(false);
  const closeConfirmModal = () => setConfirmModal(false);

  const handleAccept = async () => {
    navigate("../aceite");
  };

  const handleCancel = () => {
    if (dados[saldoMes] < 0) openErrorModal();
    else openConfirmModal();
  };

  const cancelContract = async () => {
    setLoading(true);
    await setDoc(
      doc(db, "furg2024", uid),
      { status: "c", cancelado: moment().format() },
      { merge: true }
    );
    navigate("../home");
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "furg2024", uid));

    const data = qry.data();

    if (data?.status === "a" && !data?.aceite25) {
      setDados(data);
      setLoading(false);
    } else {
      navigate("../home");
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal show={errorModal} onHide={closeErrorModal}>
        <Modal.Header closeButton>
          <Modal.Title>Aviso</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Você já utilizou créditos desse mês. Para realizar o cancelamento
          entre em contato com o financeiro.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeErrorModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={confirmModal} onHide={closeConfirmModal}>
        <Modal.Header closeButton>
          <Modal.Title>Atenção!</Modal.Title>
        </Modal.Header>
        <Modal.Body>O contrato será cancelado. Você tem certeza?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={closeConfirmModal}>
            Não
          </Button>
          <Button variant="primary" onClick={cancelContract}>
            Sim
          </Button>
        </Modal.Footer>
      </Modal>

      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      {!loading && (
        <Frame
          body={
            <div className="p-2">
              <h5>RENOVAÇÃO DE CONTRATO</h5>
              <hr />

              <p>O seu contrato atual encerra no dia 31/08/2024.</p>

              <p>
                Para continuar utilizando os serviços, clique no botão azul e
                leia atentamente os novos termos na tela a seguir.
              </p>

              <p>
                Caso não deseje continuar utilizando o serviço, selecione o
                botão vermelho e o contrato será cancelado.
              </p>

              <p>Selecione uma das opções abaixo para prosseguir:</p>

              <Button variant="danger m-3 p-3" onClick={handleCancel}>
                Quero encerrar meu contrato
              </Button>

              <Button variant="primary m-3 p-3" onClick={handleAccept}>
                Quero renovar meu contrato
              </Button>
            </div>
          }
        />
      )}
    </>
  );
};

export default Renovacao;
