import { Form } from "react-bootstrap";

const Switch = (props) => {
  return (
    <Form.Check
      checked={props.checked ? true : false}
      type="switch"
      label={props.label}
      onChange={props.onChange}
      id={props.id}
      name={props.id}
      className="small mb-3 mt-2"
    />
  );
};

export default Switch;
