import { Form, Col } from "react-bootstrap";

const Select = (props) => {
  return (
    <Form.Group as={Col} sm={props.size || 6} controlId={props.id}>
      <Form.Label>{props.label}</Form.Label>
      <Form.Select
        value={props.value}
        name={props.id}
        onChange={props.onChange}
        isInvalid={props.erro ? true : false}
      >
        {props.options}
      </Form.Select>

      <Form.Control.Feedback type="invalid">{props.erro}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default Select;
