import { useState, useEffect } from "react";
import { Frame, Loading, Header, Print, VerContrato } from "../components";
import { useNavigate } from "react-router-dom";
import { db, auth } from "../config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";

const Contrato = () => {
  const [dados, setDados] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const getData = async () => {
    const qry = await getDoc(doc(db, "furg2024", uid));

    const data = qry.data();

    if (data?.status === "a") {
      if (data?.aceite25) {
        setDados(data);
        setLoading(false);
      } else {
        navigate("../renovacao");
      }
    } else {
      navigate("../home");
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      {!loading && (
        <Frame
          body={
            <div className="px-2">
              <VerContrato dados={dados} />
              <Print />
            </div>
          }
        />
      )}
    </>
  );
};

export default Contrato;
