import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCejODaTGq8cNyvJvIDuW15K0GevA5Vd7w",
  authDomain: "fretamento-c7161.firebaseapp.com",
  projectId: "fretamento-c7161",
  storageBucket: "fretamento-c7161.appspot.com",
  messagingSenderId: "67119965377",
  appId: "1:67119965377:web:6f03720a92d7455c16e040",
  measurementId: "G-KSCZQD81GN",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const auth = getAuth(app);

export const storage = getStorage(app);
