import { useState, useEffect } from "react";
import { Frame, Loading, Header, Input } from "../components";
import { useNavigate } from "react-router-dom";
import { ValidaForm6 } from "../components/Validacao";
import { db, auth, storage } from "../config/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

const Form6 = () => {
  const [erro, setErro] = useState({});
  const [dados, setDados] = useState({});
  const [loading, setLoading] = useState(true);

  let uid = auth.currentUser?.uid;

  const navigate = useNavigate();

  const uploadFile = async (file, docPath, docUrl) => {
    if (!file) return;

    setLoading(true);

    const path = ref(storage, `furg2024/${uid}/${docPath}`);

    const upload = uploadBytesResumable(path, file, file.type);

    upload.on(
      "state_changed",
      () => {},
      () => {},
      () => {
        getDownloadURL(upload.snapshot.ref).then((url) => {
          setDados({ ...dados, [docUrl]: url });
          setLoading(false);
        });
      }
    );
  };

  const setData = async () => {
    await setDoc(doc(db, "furg2024", uid), dados, { merge: true });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const err = ValidaForm6(dados);

    if (err) {
      setErro(err);
      setLoading(false);
    } else {
      setData().then(() => navigate("../inicio"));
    }
  };

  const getData = async () => {
    const qry = await getDoc(doc(db, "furg2024", uid));

    const data = qry.data();

    if (data?.status) {
      navigate("../home");
    } else {
      setDados(data);
      setLoading(false);
    }
  };

  const checkLogin = async () => {
    if (uid) getData();
    else
      onAuthStateChanged(auth, (user) => {
        if (user?.uid) {
          uid = user.uid;
          getData();
        } else navigate("../login");
      });
  };

  useEffect(() => {
    checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Header nome={dados?.nome} foto={dados?.urlFoto} />
      <Frame
        body={
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            className="text-start"
            noValidate
          >
            <div className="row mt-3">
              <div className="col-sm-12">
                <Input
                  id="imgRg"
                  label="RG"
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  className={erro.rg && "is-invalid"}
                  onChange={(e) => uploadFile(e.target.files[0], "rg", "urlRg")}
                  erro={erro.rg}
                />
              </div>
              {dados?.urlRg && (
                <a
                  href={dados?.urlRg}
                  target="_blank"
                  rel="noreferrer"
                  className="dark m-2"
                >
                  🔎 Ver documento enviado
                </a>
              )}
            </div>

            <div className="row mt-3">
              <div className="col-sm-12">
                <Input
                  id="imgVinculo"
                  label={"Comprovante de Vínculo"}
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  className={erro.vinculo && "is-invalid"}
                  onChange={(e) =>
                    uploadFile(e.target.files[0], "vinculo", "urlVinculo")
                  }
                  erro={erro.vinculo}
                />
              </div>
              {dados?.urlVinculo && (
                <a
                  href={dados?.urlVinculo}
                  target="_blank"
                  rel="noreferrer"
                  className="dark m-2"
                >
                  🔎 Ver documento enviado
                </a>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-sm-12">
                <Input
                  id="imgResidencia"
                  label={"Comprovante de Residência"}
                  type="file"
                  accept=".jpg, .jpeg, .png, .pdf"
                  className={erro.residencia && "is-invalid"}
                  onChange={(e) =>
                    uploadFile(e.target.files[0], "residencia", "urlResidencia")
                  }
                  erro={erro.residencia}
                />
              </div>
              {dados?.urlResidencia && (
                <a
                  href={dados?.urlResidencia}
                  target="_blank"
                  rel="noreferrer"
                  className="dark m-2"
                >
                  🔎 Ver documento enviado
                </a>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-sm-12">
                <Input
                  id="imgFoto"
                  label={"Foto 3x4 para Carteirinha"}
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  className={erro.foto && "is-invalid"}
                  onChange={(e) =>
                    uploadFile(e.target.files[0], "foto", "urlFoto")
                  }
                  erro={erro.foto}
                />
              </div>
              {dados?.urlFoto && (
                <a
                  href={dados?.urlFoto}
                  target="_blank"
                  rel="noreferrer"
                  className="dark m-2"
                >
                  🔎 Ver documento enviado
                </a>
              )}
            </div>
            <div className="row mt-3">
              <div className="col-sm-12 d-grid">
                <button type="submit" className="btn btn-primary">
                  ENVIAR
                </button>
              </div>
            </div>
          </form>
        }
        title="Documentos"
      />
    </>
  );
};

export default Form6;
