import { Frame } from "../components/";
import { Accordion } from "react-bootstrap";

const Instrucoes = () => {
  return (
    <>
      <Frame
        body={
          <div className="justify">
            <div className="mt-3">
              <p>
                Seja bem-vindo ao serviço de Fretamento FURG da Expresso
                Embaixador!
              </p>
              <p>
                Se você é estudante, professor ou servidor da FURG, a Embaixador
                te busca em casa e te leva em segurança para o Campus Carreiros
                da FURG em Rio Grande, nos dias e turnos em que você precisar e
                escolher, de segunda a sexta-feira.
              </p>
              <p>
                Após o seu compromisso na Universidade, a Embaixador também leva
                você de volta para o mesmo endereço em que te buscou.
              </p>
              <p>
                Realize seu <a href="./cadastro">cadastro</a> e leia com atenção
                as condições estabelecidas no contrato. Veja abaixo as
                informações principais:
              </p>
            </div>

            <Accordion className="my-4" alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>COMO FUNCIONA?</Accordion.Header>
                <Accordion.Body>
                  <div className="p-1">
                    <p>
                      Diariamente, nos turnos da manhã, tarde, e noite, a
                      Embaixador disponibilizará ônibus para diferentes rotas
                      dentro da cidade de Pelotas*, os quais coletarão cada um
                      dos passageiros nos locais e horários por eles escolhidos
                      e indicados no momento do seu cadastro.
                    </p>
                    <p>
                      Após a coleta, a Embaixador leva o passageiro até o ponto
                      de embarque do ônibus, localizado à esquina das Ruas Dom
                      Pedro II e Manduca Rodrigues, em Pelotas/RS (ao lado do
                      Supermercado Guanabara). É desse local que sairá o
                      transporte de Pelotas até a FURG - Campus Carreiros.
                    </p>
                    <p>
                      <i>
                        * Confira abaixo as zonas de coleta e seus pontos
                        extremos, os horários previstos para coleta em cada uma
                        delas e os horários de partida de Pelotas/Rio Grande.
                      </i>
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>REGIÕES ATENDIDAS E LIMITES</Accordion.Header>
                <Accordion.Body>
                  <div className="p-1">
                    <div className="fw-bold">Areal</div>
                    <div> • Avenida Ferreira Viana até a ponte do Laranjal</div>
                    <div>
                      • Avenida Domingos de Almeida até a rótula da Zezé
                    </div>
                    <hr />
                    <div className="fw-bold">Centro</div>
                    <div> • Atendemos toda a região central</div>
                    <hr />
                    <div className="fw-bold">Fragata</div>
                    <div>
                      • Avenida Cidade de Lisboa esquina rua Paulo Zanotta
                    </div>
                    <hr />
                    <div className="fw-bold">Três Vendas</div>
                    <div>
                      • Avenida Fernando Osório até o frigorífico Castro
                    </div>
                    <div> • Avenida Idelfonso Simões Lopes até a Lorenzet</div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>COMO CONTRATAR?</Accordion.Header>
                <Accordion.Body>
                  <div className="p-1">
                    <p>
                      • Primeiro, preencha seus dados básicos no cadastro no
                      portal <a href="./login">clicando aqui.</a>
                    </p>
                    <p>
                      • Após, envie seus documentos. RG, comprovante de
                      residência, seu comprovante de vínculo com a FURG e uma
                      foto para utilizar em sua carteirinha de identificação do
                      fretamento.
                    </p>
                    <p>
                      • A seguir, indique os dias em turnos em que pretende
                      utilizar do serviço, de acordo com suas necessidades.
                    </p>
                    <hr />
                    <p>
                      Após essas etapas, a Embaixador entrará em contato com
                      você para confirmar seu cadastro e disponibilizar seu
                      contrato para assinatura, acompanhado de sua carteirinha
                      de identificação e das informações para pagamento.
                    </p>
                    <p>
                      Após assinado, o contrato terá validade para todo o
                      semestre acadêmico, com pagamentos mensais enquanto
                      utilizar (sujeito a alterações para adequação ao
                      calendário da FURG).
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>HORÁRIOS</Accordion.Header>
                <Accordion.Body>
                  <div className="p-1">
                    <div>
                      <b>IDA - SAÍDA LATERAL DO GUANABARA DOM PEDRO II</b>
                    </div>
                    <div className="mt-2">
                      <div>• Manhã: 06:45h</div>
                      <div>• Tarde: 12:10h</div>
                      <div>• Noite: 17:45h</div>
                    </div>
                    <hr />
                    <div className="mt-2">
                      <b>RETORNO - SAÍDA PAVILHÃO 4 / CARREIROS</b>
                    </div>
                    <div className="mt-2">
                      <div>• Manhã: 12:30h</div>
                      <div>• Tarde: 17:30h e 19:00h</div>
                      <div>• Noite: 22:30</div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>VALORES E DESCONTO</Accordion.Header>
                <Accordion.Body>
                  <div className="p-1">
                    <p>
                      • O valor da mensalidade é de R$ 610,00, que pode ser
                      parcelado em até 3x no cartão de crédito (na empresa).
                    </p>
                    <p>
                      • Se o pagamento for realizado à vista em dinheiro, PIX,
                      ou cartão de débito até o 5º dia útil do mês a que
                      corresponde, será concedido desconto e o valor da
                      mensalidade é reduzido para R$ 550,00.
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>COMO UTILIZAR?</Accordion.Header>
                <Accordion.Body>
                  <div className="p-1">
                    <p>
                      • Após seu cadastro e aprovação do seu contrato no portal
                      do fretamento, a Embaixador disponibilizará carteirinha
                      digital para sua identificação. Essa carteirinha será
                      carregada mensalmente, mediante pagamento da mensalidade,
                      com 44 créditos válidos para utilização do serviço somente
                      naquele mês a que corresponder o pagamento.
                    </p>
                    <p>
                      • O ônibus da Embaixador passará no endereço indicado
                      (mediante confirmação de local exato de embarque pela
                      empresa) em horário previamente estabelecido, de acordo
                      com o indicado na grade de horários no momento do
                      cadastro. Não é permitido o embarque em local diferente do
                      que for informado no cadastro.
                    </p>
                    <p>
                      • A não-utilização ou utilização do fretamento em
                      turno/horário diferente do informado no cadastro deverá
                      ser solicitada com no mínimo 24h de antecedência
                      diretamente no telefone (53) 98122-2534.
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>USO NA LINHA REGULAR</Accordion.Header>
                <Accordion.Body>
                  <div className="p-1">
                    <p>
                      • Dentro do mês de validade, a carteirinha digital poderá
                      ser utilizada na linha regular atendida pela Embaixador
                      (Pelotas x Rio Grande x Pelotas) nos horários da
                      modalidade comum (menos feriados) no trajeto Pelotas x
                      Vila Maria x Pelotas (parada em frente FURG).
                    </p>
                    <p>
                      • No ato do embarque, a carteirinha deverá ser apresentada
                      ao colaborador da empresa, que emitirá bilhete de passagem
                      (BPe) em valor correspondente a uma viagem, calculado
                      proporcionalmente ao valor da mensalidade pago, e
                      descontará um crédito da sua carteirinha.
                    </p>
                    <p>
                      • Se o passageiro não portar a carteirinha, não tiver
                      créditos válidos, ou for utilizado em outro trajeto ou
                      modalidade diferente das indicadas, será cobrada a
                      diferença entre o valor do bilhete de passagem do
                      fretamento e o valor de tarifa da linha regular vigente.
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>POLÍTICA DE CANCELAMENTO</Accordion.Header>
                <Accordion.Body>
                  <div className="p-1">
                    <p>
                      • Estando com sua mensalidade em dia, você pode avisar com
                      antecedência mínima de 30 dias que não desejará utilizar o
                      fretamento no mês seguinte. Dessa forma, seu contrato será
                      cancelado. Confirma mais informações na cláusula 6 (DAS
                      HIPÓTESES DE RESCISÃO) do contrato de fretamento no
                      portal.
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>CONTATO</Accordion.Header>
                <Accordion.Body>
                  <div className="p-1">
                    <p>
                      Para maiores informações, entre em contato pelo telefone
                      (53) 3278-7000 (Whatsapp).
                    </p>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        }
        footer={
          <div>
            <hr />
            <div className="mt-1">
              Já possui cadastro?{" "}
              <a href="/login" className="text-dark">
                Faça login
              </a>
            </div>
            <div className="mt-1">
              Não é cadastrado?{" "}
              <a href="cadastro" className="text-dark">
                Cadastre-se aqui
              </a>
            </div>
          </div>
        }
        logo
        copyright
      />
    </>
  );
};

export default Instrucoes;
